<template>
  <div class="up-modal-overlay" v-if="visible">
    <div class="up-modal">
      <h2 class="h5">نسخه جدید</h2>
      <p class="fs-7">نسخه جدید آماده است! برای دریافت آن دکمه بارگذاری را بزنید</p>
      <button class="btn btn-primary mb-0 w-100" @click="refreshPage">بارگذاری</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    refreshPage() {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          for (let registration of registrations) {
            registration.unregister();
          }
          window.location.reload();
        });
      } else {
        window.location.reload();
      }
    }
  }
}
</script>

<style scoped>
.up-modal-overlay {
  position: fixed;
  bottom: 16px;
  right: 16px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.up-modal {
  background: #000000;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}
</style>